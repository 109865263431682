@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600&display=swap');



* {
   box-sizing: border-box;
}

html {
   font-size: 10px;
   scroll-behavior: smooth;
   overflow-x: hidden;
}
body {
   font-family: 'Jost', sans-serif;
   font-weight: 500;
   line-height: 1.3;
   overflow: hidden;
   padding: 0;
   margin: 0;
   color: #111;
} 

.container {
   padding: 0 16px;
   margin: 0 auto;
}
.btn {
   font-size: 1.6rem;
   font-weight: 500;
   letter-spacing: .03em;
   color: #fff;
   text-decoration: none;
   display: inline-flex;
   align-items: center;
   background-image: linear-gradient(to right, #036FC3 0%, #0BA0F9 50%, #036FC3 100%);
   background-size: 200%;
   box-shadow: 0px 15px 30px rgba(8, 114, 201, 0.25);
   border-radius: 40px;
   padding: 12px 65px 12px 25px;
   position: relative;
   height: 48px;
   transition: all .4s ease;
}
 
.btn:hover {
   background-position-x: right;
   transition: all .4s ease;
}
 
.btn span {
   background-color: rgba(255, 255, 255, 0.2);
   background-image: url(../img/icons/arrow_right.png);
   background-repeat: no-repeat;
   background-position: center;
   border-radius: 50%;
   width: 38px;
   height: 38px;
   margin-right: 5px;
   text-align: center;
   position: absolute;
   right: 0;
   top: 4px;
}
.section__title {
   font-size: 2.4rem;
   font-weight: 600;
   color: #000;
   margin-top: 0;
   margin-bottom: 0;
 
}
.section__title_lite {
  color: #fff;
}

.section__title_line {
  position: relative;
}
.section__title_line::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 50%;
  top: -8px;
  background-color: #E43137;
  width: 2px;
  height: 40px;
}

/* ///////////////////////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   .btn {
      width: 222px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .section__title_line::before {
      height: 64px;
      top: -24px;
   }
   .section__title {
      font-size: 3rem;
   }
   .container {
      padding: 0 24px;
   }
   .btn {
      padding-left: 32px;
      padding-right: 55px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 992px) {
   
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
   .section__title {
      font-size: 4.8rem;
   }
   
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1440px) {
   .container {
      width: 1380px;
   }
   .section__title_line::before {
      height: 115px;
      top: -27px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1920px) {
   .container {
      width: 1552px;
   }
   .btn {
      font-size: 2rem;
      width: 259px;
      height: 56px;
   }
   .btn span {
      width: 46px;
      height: 46px;
   }
}
.header {
   position: relative;
   height: 1240px;
}
.header__main,
.header__top {
   width: 100%;
}
.header__top {
   position: relative;
}
.header__content {
   background-image: url(../img/header_mobile_bg.jpg); 
   background-repeat: no-repeat;
   background-position: bottom 0 right 0;
   background-size: cover;
   background-color: #F5F8FE;
   width: 100%;
   min-height: 485px;
   margin-bottom: 104px;
}
.header__content .container {
   display: flex;
   align-items: flex-start;
   flex-direction: column;
}
.header__content .container:last-of-type {
   height: 440px;
}
.header__text {
   position: relative;
   z-index: 10;
}
.header__title {
   margin-bottom: 12px;
   color: #fff;
   font-size: 2.6rem;
}
.header__subtitle {
   font-size: 1.6rem;
   font-weight: 400;
   color: #fff;
   margin-bottom: 24px; 
}
.logo__link {
   display: block;
   height: 40px;
}
.logo__img {
   height: 100%;
}
.logo__container {
   width: 100%;
   height: 70px;
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.logo__container__link {
   font-size: 1.6rem;
   letter-spacing: 0.03em;
   color: #003161;
   display: none;
}


.header__specials {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 32px;
   margin-bottom: 20px;
}

.spacial__btn {
   font-size: 1.4rem;
   font-weight: 400;
   letter-spacing: .03em;
   color: #fff;
   text-decoration: none;
   display: inline-flex;
   align-items: center;
   background-image: linear-gradient(to right, #036FC3 0%, #0BA0F9 50%, #036FC3 100%);
   background-size: 200%;
   box-shadow: 0px 15px 30px rgba(8, 114, 201, 0.25);
   padding: 11px 16px;
   position: relative;
   transition: all .4s ease;
}
 
.spacial__btn:hover {
   background-position-x: right;
   transition: all .4s ease;
}

.spacial__btn span {
   position: relative;   
   display: block;
   width: 23px;
   height: 23px;
   margin-right: 8px;
}
.spacial__btn span:before,
.spacial__btn span:after {
   content: "";
   display: block;
   background-color: #fff;
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}

.spacial__btn span:before {
   width: 23px;
   height: 23px;
   opacity: .2;
}
.spacial__btn span:after {
   width: 11px;
   height: 11px;
}
.special__sign {
   font-size: 1.4rem;
   color: #fff;
   width: 130px;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
}
.special__sign div {
   text-align: center;
   max-width: 92px;
}
.sign {
   position: absolute;
   width: 127px;
   height: 60px;
   top: -15px;
}
.sign_desktop {
   display: none;
}
.arrow_blue {
   display: none;
}
.header__photo {
   display: none;
   position: absolute;
   bottom: -95px;
   right: 0;
}

.header__features {
   margin-bottom: 8px;
}
.feature {
   display: flex;
   margin-bottom: 16px;
   font-weight: 400;
}
.feature__pin {
   margin-right: 16px;
   width: 24px;
   height: 24px;
}
.feature__text {
   font-size: 1.6rem;
   color: #fff;
}
.free-block {
   padding: 24px 16px;
   background: #fff;
   box-shadow: 0px 10px 40px rgba(3, 70, 136, 0.1);
   margin: 0 auto;
   position: relative;
}
.free-block__text {
   font-size: 2rem;
   color: #111;
   font-weight: 600;
   margin-top: 0;
   margin-bottom: 16px;
   width: 256px;
}
.free-block__sign {
   display: none;
}
.achievements {
   display: flex;
   justify-content: center;
   margin-bottom: -120px;
   flex-direction: column;
   position: relative;
   z-index: 1;
}
.achievements:before {
   content: "";
   display: block;
   height: 80%;
   border-left: 2px dashed rgba(17, 17, 17, .15);
   border-top: 2px dashed rgba(17, 17, 17, .15);
   position: absolute;
   top: 10%;
   left: 50%;
   z-index: 1;
}
.achievements:after {
   content: "";
   position: absolute;
   display: block;
   background-image: url('../img/dots.png');
   background-repeat: no-repeat;
   background-size: cover;
   width: 30px;
   height: 90px;
   top: -60px;
   right: 5%;
   animation: 1.5s linear 0s infinite alternate swim_dots;
}
.achievements__bg {
   display: none;
}
.achievements__bg:before {
   content: "";
   position: absolute;
   display: block;
   background-image: url('../img/dots.png');
   background-repeat: no-repeat;
   background-size: cover;
   width: 30px;
   height: 90px;
   top: -48px;
   right: 5%;
   animation: 1.5s linear 0s infinite alternate swim_dots;
}

.achievement {
   position: relative;
   z-index: 2;
   display: inline-block;
   text-decoration: none;
   box-shadow: 0px 10px 80px rgba(3, 70, 136, 0.1);
   width: 288px;
   padding: 20px 16px;
   background-color: gray;
   color: #535565;
   margin: 0 auto 16px;
   opacity: 1;
}
.achievement:before,
.achievement:after {
   content: "";
   display: block;
   width: 292px;
   height: 79px;
   position: absolute;
   left: -2px;
   z-index: -2;
}

.achievement:before {
   top: -2px;
   background-image: linear-gradient(140deg, #003161 5%, #fff 25%);
}
.achievement:after {
   bottom: -2px;
   background-image: linear-gradient(320deg, #003161 5%, #fff 25%);
}

.achievement__head {
   display: flex;
   align-items: center;
   margin-bottom: 15px;
}
.achievement__head:before {
   content: "";
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #fff;
   z-index: -1;
}
.achievement__icon {
   width: 77px;
   height: 56px;
   margin-right: -4px;
   background-position: center;
   background-size: contain;
}
.achievement__icon img {
   width: 100%;
}
.achievement__number {
   color: #003161;
   font-size: 2.4rem;
   font-weight: 600;
   position: relative;
}
.achievement__number span {
   font-size: 1.6rem;
   font-weight: 600;
}
.achievement__text {
   max-width: 300px;
   color: #003161;
   font-size: 1.6rem;
   font-weight: 400;
   margin: 0 auto;
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   .header__content {
      min-height: 455px;
      margin-bottom: 80px;
      background-size: 100% 89%;
      background-position: 0 0;
   }
   .spacial__btn {
      font-size: 1.6rem;
   }
   .special__sign div {
      max-width: 250px;
   }
   .special__sign {
      width: 300px;
   }
   .sign {
      display: none;
   }
   .sign_desktop {
      display: block;
      position: absolute;
      width: 300px;
      height: 65px;
      top: -25px;
      right: 0;
   }
   .free-block {
      padding: 34px 24px;
      width: 100%;
   }
   .free-block__wraper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
   }
   .achievements:after {
      right: 25%;
   }
   .achievements__bg:before {
      right: 25%;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .header {
      height: 940px;
   }
   .logo__link {
      height: 48px;
   }
   .logo__container__link {
      display: block;
   }
   .header__specials {
      margin-top: 55px;
   }
   .spacial__btn {
      padding: 12px 24px;
   }
   .spacial__btn span {
      margin-right: 15px;
   }
   .header__content {
      min-height: 455px;
      margin-bottom: 85px;
   }
   .header__title {
      font-size: 4.2rem;
   }
   .header__subtitle {
      font-size: 2rem;
   }
   .header__features {
      display: flex;
      justify-content: space-between;
      margin-bottom: 48px;
      width: 100%;
   }
   .feature {
      display: flex;
      margin-bottom: 0;
   }
   .feature__text {
      max-width: 188px;
   }
   .free-block__text {
      font-size: 2.4rem;
      margin-top: 0;
      margin-bottom: 0;
   }
   .free-block__sign {
      display: block;
   }
   .achievements {
      flex-direction: row;
   }
   .achievements:before {
      height: 2px;
      width: 60%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
   }
   .achievements:after {
      display: none;
   }
   .achievements__bg:before {
      left: -5%;
      top: -32px;
      width: 35px;
   }
   .achievements__bg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -53%);
      width: 475px;
      height: 282px;
      background: #f5f8fe;
   }
   .achievement {
      width: 230px;
   }
   .achievement:before,
   .achievement:after {
      width: 234px;
      height: 120px;
   }
   .achievement:before {
      background-image: linear-gradient(135deg, #003161 5%, #fff 35%);
   }
   .achievement:after {
      background-image: linear-gradient(315deg, #003161 5%, #fff 35%);
   }
   .achievement__head {
      flex-direction: column;
      align-items: start;
   }
   .achievement__icon {
      margin-bottom: 9px;
   }
   .arrow_blue {
      display: block;
      margin-right: -50px;
      margin-bottom: -30px;
      width: 199px;
      height: 44px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 992px) {
   
   .header__main {
      width: 800px;
      margin: 60px auto;
   }
   .free-block__text{
      width: auto;
   }
   .achievements__bg {
      width: 750px;
   }
   .achievements__bg:before {
      left: 5%;
      top: -20px;
   }
   .achievement {
      width: 270px;
   }
   .achievement:before,
   .achievement:after {
      width: 274px;
      height: 95px;
   }
   .achievement:before {
      background-image: linear-gradient(140deg, #003161 5%, #fff 30%);
   }
   .achievement:after {
      background-image: linear-gradient(320deg, #003161 5%, #fff 30%);
   }
   .achievement__head {
      flex-direction: row;
      align-items: center;
   }
   .arrow_blue {
      /* margin-right: -50px; */
      margin-bottom: -45px;
      height: 70px;
      width: 290px;
   }
}

/* //////////////////////////////////////////////// */


@media screen and (min-width: 1200px) {
   .header__content {
      background-image: url(../img/header-deacktop-bg.jpg); 
   }
}

/* //////////////////////////////////////////////// */


@media screen and (min-width: 1440px) {
   .header {
      height: 1315px;
   }
   .header__content {
      height: 685px;
      background-color: #fff;
      background-size: 98% 100%;
      background-position: 100% 0;
      margin-bottom: 212px;
   }
   .header__content .container:last-of-type {
      height: auto;
   }
   .logo__container {
      height: 80px;
   }
   .logo__link {
      height: 60px;
   }
   .logo__container__link {
      font-size: 2rem;
      margin-right: 129px;
   }
   .special__sign {
      width: 344px;
   }
   .sign_desktop {
      width: 321px;
      height: 73px;
      /* stroke-dasharray: 703; */
   }
   .special__sign div {
      font-size: 1.8rem;
      letter-spacing: 0.06em;
      margin-left: 24px;
   }
   .feature__pin {
      margin-right: 8px;
      width: 32px;
      height: 32px;
   }
   .header__features {
      width: 98%;
   }
   .header__top {
      display: flex;
   }
   .header__main {
      margin: 147px 0 135px;
      width: 760px;
   }
   .header__photo {
      display: block;
      margin-right: -85px;
      bottom: -93px;
   }
   .feature__text {
      max-width: 210px;
   }
   .free-block__text {
      font-size: 3rem;
   }
   .free-block {
      padding: 34px 85px;
      width: 120%;
   }
   .free-block__wraper {
      width: 1148px;
   }
   .arrow_blue {
      margin-bottom: -65px;
      margin-right: -125px;
   }
   .achievements {
      justify-content: space-between;
   }
   .achievements__bg {
      width: 1148px;
      height: 281px;
      top: 54%;
   }
   .achievements__bg:before {
      left: 7%;
   }
   .achievements__bg:after {
      content: "";
      position: absolute;
      display: block;
      background-image: url('../img/dots.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 41px;
      height: 90px;
      top: 105px;
      right: -15%;
      transform: rotateZ(90deg);
      animation: 1.5s linear 0s infinite alternate swim_dots;
   }
   .achievement {
      width: 400px;
      padding: 28px 32px;
      margin: 0;
   }
   .achievement:before,
   .achievement:after {
      width: 404px;
      height: 115px;
   }
   .achievement:before {
      background-image: linear-gradient(150deg, #003161 5%, #fff 35%);
   }
   .achievement:after {
      background-image: linear-gradient(330deg, #003161 5%, #fff 35%);
   }
   .achievement__head {
      flex-direction: row;
      align-items: center;
      margin-bottom: 12px;
   }
   .achievement__text {
      margin: 0;
   }
   .achievements__bg:before {
      width: 41px;
      top: -55px;
      right: 5%;
   }
   .achievement__icon {
      width: 132px;
      height: auto;
      margin-bottom: 0;
      margin-right: -17px;
   }
   .achievement__number {
      font-size: 4rem;
   }
   .achievement__number span {
      font-size: 1.8rem;
   }
}

/* //////////////////////////////////////////////// */


@media screen and (min-width: 1920px) {
   .header {
      height: 1365px;
   }
   .header__content {
      height: 730px;
   }
   .header__main {
      width: 865px;
   }
   .spacial__btn {
      font-size: 2rem;
      padding: 16px 24px;
   }
   
   .header__title {
      font-size: 8.4rem;
   }
   .header__subtitle {
      font-size: 3rem;
      margin-bottom: 34px;
      max-width: 780px;
   }
   .feature__text {
      font-size: 1.8rem;
   }
   .header__specials {
      margin: 0;
   }
   .header__features {
      margin-bottom: 0;
   }
   .free-block {
      padding: 56px 128px;
   }
   .free-block__wraper {
      width: 1248px;
   }
   .free-block__text {
      font-size: 3.6rem;
   }
   .achievements__bg {
      width: 1248px;
   }
   
   .achievements__bg:after {
      right: -16%;
   }
   .achievement {
      width: 480px;
   }
   .achievement__text {
      font-size: 1.8rem;
      max-width: 100%;
   }
   .achievement:before,
   .achievement:after {
      width: 484px;
   }
   .achievement:before {
      background-image: linear-gradient(155deg, #003161 5%, #fff 35%);
   }
   .achievement:after {
      background-image: linear-gradient(335deg, #003161 5%, #fff 35%);
   }
}















.line {
   animation-timing-function: linear;
   animation-fill-mode: forwards;
   animation-name: line;
   /* animation-delay: 1s; */
}


@keyframes line {
   to {
      stroke-dashoffset: 0;
   }
}
.reviews {
   background-image: url(../img/header_mobile_bg.jpg); 
   background-repeat: no-repeat;
   background-position: 0 0;
   background-size: 100% 55%;
   margin-bottom: 40px;
}
.reviews .container {
   padding: 0;
}
.reviews__title {
   text-align: center;
   padding-top: 40px;
   color: #fff;
   margin-bottom: 32px;
}
#reviews__slider {
   width: 100%;
   overflow: hidden;
}
.controls {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 24px;
}
.reviews__counter {
   display: none;
   font-size: 1.8rem;
   font-weight: 400;
   color: #535565;
   margin-right: 42px;
   letter-spacing: 0.15em;
}
.reviews__counter span {
   font-size: 2.6rem;
   color: #003161;
}

.reviews__pagination {
	display: none;
   position: static;
   margin-right: 32px;
}
.swiper-pagination-bullet {
   border-radius: 0;
   height: 2px;
   width: 36px;
   background: #E6ECF8;
}
.swiper-pagination-bullet-active {
   background: #E43137;
}

.buttons__wraper {
   height: 40px;
   width: 90px;
   display: flex;
   justify-content: space-between;
}

.reviews__button-next, 
.reviews__button-prev {
   position: static;
   width: 40px;
   height: 40px;
   background-color: #F5F8FE;
   border-radius: 50%;
   overflow: hidden;
   margin: 0;
   color: #111;
   transition: all .2s ease-in;
}
.reviews__button-next:after, 
.reviews__button-prev:after {
   content: "";
}
.reviews__button-next:hover,
.reviews__button-prev:hover,
.reviews__button-next:focus,
.reviews__button-prev:focus,
.reviews__button-next:active,
.reviews__button-prev:active {
   background-color: #E43137;
}
.reviews__arrow {
   fill: #111;
   transition: fill .2s ease-in;
}
.reviews__button-next:hover .reviews__arrow, 
.reviews__button-prev:hover .reviews__arrow,
.reviews__button-next:focus .reviews__arrow,
.reviews__button-prev:focus .reviews__arrow,
.reviews__button-next:active .reviews__arrow, 
.reviews__button-prev:active .reviews__arrow {
   fill: #fff;
}

.review__wraper {
   width: 220px !important;
   height: 277px;
   overflow: hidden;
   top: 277px;
   z-index: 10;
}
.review {
   width: 100%;
   height: 100%;
}
.plyr {
   height: 100%;
}
.reviews .swiper-wrapper {
   position: relative;
   top: -277px;
   z-index: -1;
}
.plyr--video  button.plyr__control--overlaid {
   background-color: rgba(228, 49, 55, 1);
   width: 38px;
   height: 38px;
}
.plyr--video  button.plyr__control--overlaid:before {
   content: "";
   display: flex;
   align-items: center;
   justify-content: center;
   width: 64px;
   height: 64px;
   background: rgba(228, 49, 55, .4);
   border-radius: 50%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.plyr--video button.plyr__control--overlaid:hover {
   background-color: rgb(218, 101, 105);
}
.plyr--video  button.plyr__control--overlaid svg {
   top: -4px;
   left: -3px;
}
.plyr__controls {
   display: none;
}


/* ///////////////////////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .reviews__counter {
      display: block;
   }
   .reviews__counter span:first-of-type {
      font-weight: 600;
   }
   .activeSlide {
      display: inline-block;
      width: 33px
   }
   .reviews__pagination {
      display: block;
   }
   .controls {
      margin-top: 34px;
   }
   .reviews__title {
      padding-top: 48px;
      
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 992px) {
   .reviews .container {
      padding: 0 24px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
   .review__wraper {
      width: 350px !important;
      height: 440px;
      overflow: hidden;
      top: 440px;
   }
   .reviews__button-next, 
   .reviews__button-prev {
      width: 48px;
      height: 48px;
   }
   .buttons__wraper {
      height: 48px;
      width: 104px;
   }
   .reviews .swiper-wrapper {
      top: -440px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1440px) {
   .reviews {
      background-size: 98% 53%;
      margin-bottom: 95px;
   }
   .reviews__title {
      padding-top: 89px;
      margin-bottom: 47px;
   }
   .reviews__counter span {
      font-size: 1.8rem;
   }
   .reviews__counter span:first-of-type {
      font-size: 3.2rem;
   }
   .plyr--video  button.plyr__control--overlaid {
      width: 42px;
      height: 42px;
   }
   .plyr--video  button.plyr__control--overlaid:before {
      width: 72px;
      height: 72px;
   }
   .plyr--video  button.plyr__control--overlaid svg {
      top: -3px;
      left: -1px;
   }
   .activeSlide {
      width: 33px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1920px) {
   
}
.program {
   padding: 40px 0;
   position: relative;
}
.program__container {
   position: relative;
   padding: 0 0 0 16px;
}
.program:before {
   content: "";
   display: inline-block;
   width: 100%;
   height: 260px;
   background: #F5F8FE;
   position: absolute;
   top: 0;
   z-index: -1;
}
.program__img {
   display: block;
   width: 100%;
   height: 277px;
   overflow: hidden;
   padding-right: 16px;
}
.program__img img,
.program__img source {
   width: 100%;
}
.preview__wraper {
   width: 100%;
   height: 380px;
   position: relative;
}
.program__preview {
   width: 288px;
   background: #fff;
   box-shadow: 0px 10px 80px rgba(3, 70, 136, 0.1);
   padding: 24px 16px;
   position: absolute;
   right: 0;
   top: -120px;
   z-index: 1;
}
.program__title {
   margin-bottom: 16px;
}
.program__description {
   font-size: 1.6rem;
   font-weight: 400;
   color: #535565;
   position: relative;
   margin-bottom: 16px;
}
.program__description:before {
   content: "";
   display: inline-block;
   position: absolute;
   left: -32px;
   top: 56px;
   background-color: #E43137;
   width: 2px;
   height: 80px;
}
.proggram__counters {
   height: 80px;
   min-width: 320px;
   margin-bottom: 20px;
   box-shadow: 0px 15px 30px rgba(229, 51, 55, 0.25);
   background: linear-gradient(93.31deg, #E43137 5.13%, #FF8642 100%);
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   align-items: center;
   position: relative;
   left: -48px;
}
.proggram__counters:before {
   content: "";
   position: absolute;
   display: block;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-image: url(../img/mask.png);
}
.proggram__counter {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
   font-size: 1.4rem;
   font-weight: 600;
   color: #fff;
}
.proggram__counter + .proggram__counter {
   border-left: 2px solid rgba(255, 255, 255, .2);
}
.counter__number {
   font-size: 2.4rem;
   font-weight: 600;
}
.program__features {

}
.program__feature {
   margin-bottom: 20px;
   font-size: 1.6rem;
   font-weight: 400;
   position: relative;
}
.program__feature:last-of-type {
   margin-bottom: 0;
}
.program__feature svg {
   position: absolute;
   fill: #E43137;
   top: 3px;
}
.program__feature  div {
   padding-left: 28px;
}
.program__part {
   margin-top: 40px;
   margin-bottom: 40px;
}
.program__part:last-child {
   margin-bottom: 0;
}
.part__title {
   font-size: 2rem;
   font-weight: 600;
   color: #000;
   margin-bottom: 36px;
   position: relative;
}
.part__title:before {
   content: "";
   display: inline-block;
   height: 2px;
   width: 32px;
   background: #e43137;
   position: absolute;
   bottom: -14px;
}
.part__lessons {
   margin-left: 48px;
}
.lesson {
   position: relative;
   margin-bottom: 20px;
}
.lesson:last-child {
   margin-bottom: 0;
}
.lesson__dot {
   position: absolute;
   left: -48px;
   width: 24px;
   height: 24px;
   background: #FEEAE2;
   border-radius: 50%;
}
.lesson__dot:after {
   content: "";
   display: inline-block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 10px;
   height: 10px;
   border-radius: inherit;
   background: linear-gradient(93.31deg, #E43137 5.13%, #FF8642 100%);
}
.lesson:before {
   content: "";
   display: block;
   position: absolute;
   border-right: 2px dashed rgba(17, 17, 17, .15);
   height: 110%;
   top: 12px;
   left: -37px;
   z-index: -1;
}
.lesson:last-of-type:before {
   display: none;
}
.lesson__number {
   font-size: 1.6rem;
   font-weight: 600;
   color: #003161;
}
.lesson__title {
   font-size: 1.6rem;
   font-weight: 400;
   color: #535565;
   margin: 8px 0 0;
}
.lesson__title a {
   display: block;
   font-size: 1.6rem;
   color: #003161;
   margin-top: 8px;
   font-weight: 500;
}














/* ///////////////////////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   .program__img {
      width: 350px;
      height: auto;
   }
   .program__description:before {
      content: none;
   }
   .program__preview {
      width: 435px;
      top: -290px;
   }
   .proggram__counters {
      left: -141px;
   }
   .preview__wraper {
      height: 150px;
   }
   .preview__wraper:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url('../img/circle.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      width: 240px;
      height: 440px;
   }
   .program__main {
      padding-left: 72px;
   }
   .part__title:before {
      width: 56px;
      top: 50%;
      transform: translateX(calc(-100% - 16px));
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .program {
      padding: 48px 0;
   }
   .program__container {
      padding: 0 0 0 24px;
   }
   .program__preview {
      width: 620px;
   }
   .proggram__counters {
      left: -140px;
   }
   .preview__wraper {
      height: 100px;
   }
   .preview__wraper:before {
      margin-top: -100px;
   }
   .lesson__title,
   .lesson__title a {
      display: inline-block;
      margin: 0 0 0 8px;
   }
   .lesson {
      display: flex;
      align-items: flex-end;   
   }
   .lesson:before {
      height: 130%;
      top: 18px;
   }
   .part__title {
      margin-bottom: 24px;
      font-size: 2.4rem;
   }
   .lesson__number {
      font-size: 1.8rem;
   }
   .program__part:last-child .lesson:last-of-type:before {
      display: none;
   }
   .program__preview:after {
      content: "";
      display: block;
      height: 100px;
      width: 32px;
      background-image: url("../img/dots.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -57px;
      right: 150px;
      animation: 1s linear 0s infinite alternate swim_dots;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 992px) {
   .program__img {
      width: 736px;
      padding: 0;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
   .preview__wraper {
      margin-top: -100px;
      margin-bottom: 89px;
   }
   .preview__wraper:before {
      margin-top: -70px;
   }
   .program__preview {
      width: 700px;
      transform: translateY(-100%);
      top: 0;
      padding: 36px 60px;
   }
   .program__part {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0;
   }
   .program__part:not(:first-of-type) {
      margin-top: 0;
   }
   .part__title {
      width: 340px;
      margin: 0;
   }
   .program__part:first-child .lesson:first-child {
      margin-top: 5px;
   }
   .lesson:last-child {
      margin-bottom: 29px;
   }
   .lesson:last-of-type:before {
      display: block;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1440px) {
   .program {
      padding: 112px 0 63px;
   }
   .program::before {
      width: 98%;
      height: 500px;
      right: 0;
   }
   .program__preview {
      width: 992px;
      transform: translateY(-100%);
      top: 0;
      padding: 58px 127px;
   }
   .program__description {
      font-size: 2rem;
      margin-left: 32px;
      margin-bottom: 32px;
   }
   .program__description:before {
      content: "";
      top: 0;
   }
   .program__feature {
      font-size: 1.8rem;
   }
   .program__title {
      margin-bottom: 32px;
      margin-top: -15px;
   }
   .proggram__counter {
      font-size: 1.8rem;
   }
   .counter__number {
      font-size: 4rem;
   }
   .proggram__counters {
      height: 114px;
      padding: 0 40px;
      left: -256px;
   }
   .part__title {
      font-size: 3.6rem;
      width: 512px;
   }
   .part__title:before {
      width: 96px;
      transform: translateX(calc(-100% - 32px));
   }
   .program__main {
      padding-left: 128px;
   }
   .lesson {
      margin-bottom: 27px;
   }
   .lesson__number {
      font-size: 2rem;
   }
   .lesson__title {
      font-size: 2rem;
   }
   .lesson__title a {
      font-size: 2rem;
   }
   .program__preview:after {
      height: 131px; 
      width: 42px;
      top: -90px;
      right: 290px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1920px) {
   .program__preview {
      transform: translateY(-94%);
      left: auto;
      right: 208px;
      top: -9px;
   }
   .preview__wraper:before {
      width: 400px;
      height: 740px;
      right: 0;
   }
   .lesson__number {
      font-size: 2.2rem;
   }
}
.athor {
   background-image: url('../img/athor_bg.jpg');
   background-repeat: no-repeat;
   background-size: cover;
}
.athor__container {
   display: flex;
   flex-direction: column;
   align-items: center;
}
.athor__photo {
   margin-top: -12px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-end;
   width: 100%;
   position: relative;
   z-index: 1;
   /* overflow-y: hidden; */
}
.athor__photo:before {
   content: "";
   display: block;
   position: absolute;
   width: 100%;
   height: 100%;
   top: 55px;
   left: 0;
   background-image: url('../img/double_circle.png');
   background-repeat: no-repeat;
   background-size: contain;
   z-index: -1;
}
.athor__img {
   padding-right: 15px;
}
.athor__description {
   width: 90%;
   margin: -40px auto 0;
   transform: translateY(-50%);
}
.athor__name,
.athor__title {
   margin-top: -11px;
   text-align: center;
   padding: 16px 0;
   transform: skewX(-18deg);
   font-weight: 600;
   color: #fff;
}
.athor__name div,
.athor__title div {
   transform: skewX(18deg);
}
.athor__name {
   background-image: linear-gradient(93.31deg, #E43137 5.13%, #FF8642 100%);
   font-size: 2rem;
   position: relative;
   z-index: 1;
}
.athor__title {
   background-image: linear-gradient(269.98deg, #176DC0 0%, #003161 99.96%);
   font-size: 1.8rem;
}
.athor__features {
   margin-top: 24px;
   margin-bottom: 8px;
}
.athor__feature {
   display: flex;
   align-items: flex-start;
   margin-bottom: 16px;
   font-weight: 400;
}
.athor__feature__text {
   font-size: 1.6rem;
   font-weight: 400;
   color: #fff;
}




/* ///////////////////////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   .athor__photo {
      align-items: center;
   }
   .athor__description {
      width: 60%;
   }
   .athor__features {
      margin-top: 0;
      margin-bottom: 30px;
   }
   .athor__photo::before {
      left: 60px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .athor {
      background-image: url('../img/header-deacktop-bg.jpg');
   }
   .athor__container {
      flex-direction: row-reverse;
      align-items: flex-end;
   }
   .athor__feature__text {
      max-width: 390px;
   }
   .athor__features {
      flex-grow: 1;
      margin-top: 50px;
      margin-bottom: 50px;
   }
   .athor__photo {
      width: 240px;
   }
   .athor__photo img {
      margin-top: -70px;
   }
   .athor__description {
      margin-top: 55px;
      width: 100%;
   }
   .athor__photo {
      height: 465px;
   }
   .athor__photo::before {
      width: 130%;
      top: -5px;
      left: -40px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 992px) {
   .athor__photo {
      height: 540px;
      width: 340px;
   }
   .athor__img {
      width: 100%;
      height: 100%;
   }
   .athor__img img {
      width: 100%;
      margin-top: -50px;
   }
   .athor__feature__text {
      font-size: 1.8rem;
      max-width: 520px;
   }
   .athor__description {
      margin-top: 0;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
   .athor__container {
      height: 668px;
      align-items: center;
   }
   .athor__feature__text {
      max-width: 560px;
   }
   .athor__name,
   .athor__title {
      height: 80px;
      line-height: 48px;
   }
   .athor__name {
      font-size: 3rem;
      width: 356px;
   }
   .athor__title {
      width: 372px;
   }
   .athor__title {
      font-size: 2.4rem;
   }
   .athor__description {
      position: absolute;
      bottom: 0;
      left: 0;
   }
   .athor__features {
      flex-shrink: 1;
      flex-grow: unset;
   }
   .athor__container {
      justify-content: space-between;
   }
   .athor__photo {
      flex-basis: 43%;
      height: 105%;
      top: -10px;
   }
   .athor__photo::before {
      top: 155px;
      left: -85px;
   }
   .athor__img img {
      height: 100%;
      margin-top: 0;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1440px) {
   .athor {
      background-size: 98% 100%;
   }
   .athor__photo {
      overflow-y: clip;
      flex-basis: 37%;
   }
   .athor__description {
      bottom: 25px;
      left: -130px;
   }
   .athor__features {
      margin-top: 61px;
   }
   .athor__feature {
      margin-bottom: 17px;
   }
   .athor .feature__pin {
      margin-right: 16px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1920px) {
   .athor__photo {
      flex-basis: 33%;
      right: 115px;
      top: -12px;
   }
   .athor__description {
      left: -115px;
   }
}
.certificates {
   padding: 32px 0 40px;
   position: relative;
}
.certificates:after {
   content: "";
   display: inline-block;
   width: 120%;
   height: 156px;
   background-color: #F5F8FE;
   position: absolute;
   z-index: -1;
   left: -10%;
   top: 56%;
}
.certificates__title {
   /* padding-left: 16px; */
   position: relative;
}
#certificates__slider {
   background: #fff;
   box-shadow: 0px 10px 80px rgba(3, 70, 136, 0.1);
   padding: 24px 16px;
   margin-left: 16px;
   overflow: hidden;
   position: relative;
}
.certificates__controls {
   display: flex;
   justify-content: flex-end;
}
.certificates__buttons__wraper {
   margin-bottom: 24px;
   margin-top: -35px;
}
.certificates .swiper-button-prev,
.certificates .swiper-button-next {
   transition: all .2s ease-in;
   background: #E43137;
}
.certificates .swiper-button-prev svg,
.certificates .swiper-button-next svg {
   transition: fill .2s ease-in;
   fill: #fff;
}
.certificates .swiper-button-prev:hover,
.certificates .swiper-button-next:hover,
.certificates .swiper-button-prev:active,
.certificates .swiper-button-next:active,
.certificates .swiper-button-prev:focus,
.certificates .swiper-button-next:focus {
   background: #F5F8FE;
}
.certificates .swiper-button-prev:hover svg,
.certificates .swiper-button-next:hover svg,
.certificates .swiper-button-prev:active svg,
.certificates .swiper-button-next:active svg,
.certificates .swiper-button-prev:focus svg,
.certificates .swiper-button-next:focus svg {
   fill: #111;
}
.certificate__wraper {
   border-image: url('../img/border.png') round;
   border-image-slice: calc(50 / 167 * 100) calc(80 / 260 * 100) fill;
   border-image-width: 5;
   border-width: 6px;
   border-style: solid;
   width: 236px;
}
.certificate {
   width: 224px !important;
   height: 152px;
   overflow: hidden;
   position: relative;
}
.certificate__mask {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: rgba(0, 0, 0, .4);
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: -1;
   opacity: 0;
   cursor: pointer;
   transition: all .3s ease-in;
   pointer-events: none;
}
.certificate__mask svg {
   fill: #fff;
}
.certificate:hover .certificate__mask,
.certificate__mask:hover {
   z-index: 1;
   opacity: 1;
}
.certificate__link {
   display: block;
   width: 100%;
}
.certificate__link img {
   width: 100%;
}



/* ///////////////////////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   .certificates__title {
      padding-left: 82px;
   }
   .certificates__title:before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 0;
      top: 17px;
      background-color: #E43137;
      width: 56px;
      height: 2px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   #certificates__slider {
      margin-left: 24px;
   }
   .certificates__buttons__wraper {
      margin-bottom: 32px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1440px) {
   .certificates {
      padding-top: 90px;
   }
   .certificates__title:before {
      top: 28px;
      width: 96px;
   }
   .certificates__title {
      padding-left: 128px;
   }
   #certificates__slider {
      margin-left: 55px;
      margin-top: 20px;
      padding: 40px 56px;
   }
   .certificates:after {
      width: 120%;
      height: 298px;
      top: 58%;
      left: 2%;
   }
   .certificates__buttons__wraper {
      margin-top: -55px;
   }
   .certificate__wraper {
      width: 324px;
      border-width: 10px;
   }
   .certificate {
      width: 303px !important;
      height: 212px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1920px) {
   #certificates__slider {
      margin-left: 208px;
   }
}
.after {
   padding: 40px 0;
}
.after__title {
   font-size: 2rem;
   font-weight: 600;
   max-width: 260px;
}
.after__img {
   display: none;
}
.after__main {
   margin-bottom: 43px;
}
.after__feature {
   display: flex;
   align-items: flex-start;
   margin-top: 14px;
}
.after__feature__text {
   font-size: 1.6rem;
   font-weight: 400;
   color: #535565;
}
.after__offer {
   background-image: url("../img/header_mobile_bg.jpg");
   color: #fff;
   padding: 36px 24px;
   text-align: center;
   position: relative;
}
.after__offer .btn {
   z-index: 2;
}
.after__offer:before {
   content: "";
   display: block;
   position: absolute;
   height: 50px;
   background-color: rgba(0, 49, 97, .05);
   left: 16px;
   right: 16px;
   bottom: -8px;
}
.after__offer:after {
   content: "";
   display: block;
   border: 2px dashed rgba(255, 255, 255, .2);
   position: absolute;
   top: 4px;
   bottom: 4px;
   left: 4px;
   right: 4px;
}
.offer__title {
   font-size: 2rem;
   font-weight: 600;
   margin-top: 0;
   margin-bottom: 20px;
}
.offer__subtitle {
   font-size: 1.6rem;
   font-weight: 400;
   margin: 0 auto 24px;
   max-width: 220px;
}


/* //////////////////////////////////////////////// */

@media screen and (min-width: 576px) {
   .offer__subtitle {
      max-width: none;
   }
}
@media screen and (min-width: 768px) {
   .after {
      position: relative;
      min-height: 1098px;
   }
   .after__title {
      max-width: none;
      font-size: 2.6rem;
   }
   .after__img {
      display: block;
      height: 317px;
      width: 352px;
   }
   .after__img img {
      width: 100%;
   }
   .after__img:after {
      content: "";
      display: block;
      width: 440px;
      height: 440px;
      background-image: url("../img/circle_full.png");
      background-repeat: no-repeat;
      background-size: cover;
      margin-left: -225px;
      margin-top: -35px;
      position: relative;
      z-index: 1;
   }
   .after__main {
      padding: 24px;
      width: 621px;
      background: #fff;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 176px;
      box-shadow: 0px 10px 80px rgba(3, 70, 136, 0.1);
   }
   .after__main:after {
      content: "";
      display: block;
      height: 100px;
      width: 32px;
      background-image: url("../img/dots.png");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -135px;
      right: 185px;
      animation: 1.5s linear 0s infinite alternate swim_dots;
   }
   .after__offer {
      margin-top: 405px;
      z-index: 2;
      padding: 64px 24px;
   }
   .after__offer:before {
      left: 128px;
      right: 128px;
      bottom: -16px;
   }
   .after__offer:after {
      top: 16px;
      bottom: 16px;
      left: 16px;
      right: 16px;
   }
   .offer__title {
      font-size: 2.6rem;
   }
   .offer__subtitle {
      font-size: 2rem;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
   .after__img {
      width: 736px;
      height: 664px;
   }
   .after__main {
      right: 24px;
   }
   .after__img::after {
      top: -125px; 
      left: -180px;
      width: 540px;
      height: 540px;
   }
   .after__offer {
      margin-top: 75px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1440px) {
   .after {
      padding: 152px 0 120px;
   }
   .after__title {
      font-size: 3.6rem;
      margin-bottom: 25px;
   }
   .after__feature {
      margin-top: 17px;
   }
   .after__feature__text {
      font-size: 1.8rem;
   }
   .offer__title {
      font-size: 4.8rem;
   }
   .offer__subtitle {
      font-size: 3.4em;
      margin-bottom: 34px;
   }
   .after__main {
      padding: 53px 127px;
      width: 992px;
      top: 231px;
   }
   .after__main::after {
      top: -81px;
      right: 85px;
      height: 129px;
      width: 41px;
   }
   .after__offer {
      margin-top: 175px;
      padding: 90px 24px 95px;
   }
   .after__offer:before {
      bottom: -24px;
   }
   .after .feature__pin {
      margin-right: 18px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1920px) {
   .after__main {
      right: 208px;
   }
   .after__img::after {
      width: 739px;
      height: 739px;
      left: -315px;
   }
   .after__offer::after {
      top: 24px;
      bottom: 24px;
      left: 24px;
      right: 24px;
   }
}






.footer {
   overflow-y: hidden;
}
.social {
   list-style: none;
   padding: 0;
   margin: 0;
   display: flex;
   align-items: center;
}
.social__item + .social__item {
   margin-left: 16px;
}
.social__link {
   text-decoration: none;
   transition: all .4s ease;
   width: 40px;
   height: 40px;
   border: 1px solid rgba(255, 255, 255, .15);
   border-radius: 50%;
   position: relative;
   display: block;
}
.social__link:hover {
   background-color: #E43137;
   border-color: #E43137;
}
.social__icon {
   fill: #E6ECF8;
   width: 16px;
   height: 16px;
   position: relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
}
.contacts {
   background-color: #111;
}
.contacts__container {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-top: 32px;
   padding-bottom: 32px;
}
.contacts__item {
   display: flex;
   align-items: center;

}
.contacts__item .contacts__icon:hover {
   background-color: #E43137;
}
.contacts__icon {
   margin-right: 16px;
}
.dewelopment {
   background-color: #000;
   text-align: center;
   font-size: 1.6rem;
   color: #fff;
   padding-top: 16px;
   padding-bottom: 17px;
}
.dewelopment__link {
   font-size: inherit;
   color: #E43137;
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .social__item + .social__item {
      margin-left: 8px;
   }
   .dewelopment {
      padding-bottom: 16px;
   }
}

@media screen and (min-width: 1200px) {
   .social__item + .social__item {
      margin-left: 16px;
   }
   .social__link {
      width: 48px;
      height: 48px;
      /* font-size: 2.4rem; */
   }
   .dewelopment {
      padding-top: 24px;
      padding-bottom: 23px;
      font-size: 1.8rem;
   }
   .contacts {
      background-color: #111;
   }
}
.modal {
   display: none;
}
body.modal_open .modal.open {
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   background: rgba(0,0,0,0.9);
   z-index: 10;
}
.full_certificate {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-width: 290px;
}
.close_modal {
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   bottom: 0;
   z-index: 9;
   text-decoration: none;
}
.close_modal  span {
   position: absolute;
   top: 20px;
   right: 20px;
   z-index: 11;
   color: red;
   font-size: 3rem;
}



/* //////////////////////////////////////////////// */

@media screen and (min-width: 768px) {
   .full_certificate {
      max-width: 590px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 992px) {
   .full_certificate {
      max-width: 690px;
   }
}

/* //////////////////////////////////////////////// */

@media screen and (min-width: 1200px) {
   .full_certificate {
      max-width: 1200px;
      max-height: 80%;
   }
}

@keyframes swim_dots {
   to {
      margin-top: 20px;
   }
}